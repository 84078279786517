import React from "react";
import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {CenterBox} from "@pg-design/grid-module";
import {GalleryFillIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader";
import {head} from "@pg-mono/nodash";

import {usePlansAbTest} from "../property/utils/use_plans_ab_test";

interface IProps {
    property: {
        plan: string | null;
        plan_image_pages: {
            image: {
                p_img_1500: string;
                p_img_980: string;
            };
            page: number;
        }[];
        external_plan_url: string | null;
        id: number;
    };
    offerName: string;
}

export const PropertyListPlanImage: React.FC<IProps> = (props) => {
    const {property} = props;
    const [isImageLoaded, setImageLoaded] = useState(false);

    const propertyPlanImage = head(property.plan_image_pages)?.image;
    const {abTestVariant} = usePlansAbTest();
    const useExternalPlan = abTestVariant === 0 && property?.external_plan_url;

    useEffect(() => {
        propertyPlanImage && setImageLoaded(false);
    }, [propertyPlanImage]);

    const blankIcon = (size: string) => {
        return (
            <div>
                <GalleryFillIcon size={size} />
            </div>
        );
    };

    const planPictureElement = (
        <picture css={planPicture}>
            {useExternalPlan ? null : <source media="(min-width: 1024px)" srcSet={propertyPlanImage?.p_img_1500} />}
            <img
                css={[planImage]}
                src={useExternalPlan || propertyPlanImage?.p_img_980}
                alt={props.offerName}
                onLoad={() => setTimeout(() => setImageLoaded(true), 50)}
            />
        </picture>
    );

    return (
        <div css={planImageHolder}>
            <>
                {!isImageLoaded && (
                    <div css={loaderWrapper}>
                        <CenterBox>
                            <Loader size="lg" />
                        </CenterBox>
                    </div>
                )}

                {propertyPlanImage ? (
                    useExternalPlan || property.plan ? (
                        <a css={planImageLink} href={(useExternalPlan || property.plan) as string} target="_blank">
                            {planPictureElement}
                        </a>
                    ) : (
                        planPictureElement
                    )
                ) : (
                    <BlankImage>{blankIcon("18")}</BlankImage>
                )}
            </>
        </div>
    );
};

const BlankImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.gray[300]};
    width: 100%;
    &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }

    > div {
        > svg {
            fill: ${(props) => props.theme.colors.gray[700]};
        }
    }
`;

const planImageHolder = css`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const planImageLink = css`
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const planPicture = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const planImage = css`
    object-fit: contain;
    display: block;
    max-width: 100%;
    max-height: 100%;
`;

const loaderWrapper = css`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`;
